



















import { InputSetups } from "@/mixins/input-setups";
import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';

import { Component, Mixins, Prop, VModel, Watch } from 'vue-property-decorator';

@Component({
  components: {
    SwitchInput
  }
})
export default class SitePermissions extends Mixins(InputSetups) {
  @VModel() permissionsList!: Array<SitePermissionEnum>

  @Prop({ type: Boolean, required: false, default: false }) disabled!: boolean

  @Watch('permissionsList', { immediate: true })
  inputPermissionsChange(list: Array<SitePermissionEnum>) {
    Object.keys(this.permissions).forEach(k => {
      const key = k as SitePermissionEnum

      this.$set(this.permissions, k, list.includes(key))
    })
  }

  permissions: Record<SitePermissionEnum, boolean> = {
    [ SitePermissionEnum.ChangeConfig ]: false,
    [ SitePermissionEnum.AddSiteAdmins ]: false,
    [ SitePermissionEnum.AddChatAdmins ]: false,
    [ SitePermissionEnum.Statistics ]: false,
    [ SitePermissionEnum.Posts ]: false,
  }

  permissionsChange() {
    this.permissionsList = Object.keys(this.permissions).reduce<Array<SitePermissionEnum>>((acc, key) => {
      const permission = key as SitePermissionEnum

      if (this.permissions[permission]) {
        acc.push(permission)
      }

      return acc
    }, [])
  }
}
