
















































import { InputSetups } from '../../../mixins/input-setups'
import { errorNotification, successNotification } from '@/includes/NotificationService'
import SendMessageApi from "@/includes/Api/SendMessage.api";

import MessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMessageEditorWithMediaInput/MessageEditorWithMediaInput.vue'
import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    MessageEditorWithMediaInput
  }
})
export default class VuSendMessageExtra extends Mixins(InputSetups) {
  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  model: { message: MessageEditorWithMediaData } = {
    message: {
      text                : "",
      buttons             : [],
      attachments         : [],
      remove_after        : 0,
      send_after          : 0,
      pin                 : false,
      disable_link_preview: false,
      disable_notify      : false,
      protect_content     : false,
      remove_previous     : false
    }
  }

  successMessage(): void {
    successNotification()
  }

  sendSelfMessage(): void {
    SendMessageApi.sendSelfMessage({
      message: [ this.model.message ],
      chat_id: this.$store.getters.chatId
    })
      .then(this.successMessage)
      .catch(errorNotification);
  }

  sendChatMessage(): void  {
    SendMessageApi.sendChatMessage({
      message: [ this.model.message ],
      chat_id: this.$store.getters.chatId
    })
      .then(this.successMessage)
      .catch(errorNotification);
  }
}
