var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"site-permissions"},_vm._l((Object.keys(_vm.permissions)),function(key,index){return _c('switch-input',{key:index,attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.permissions,
        'key': key,
        'disabled': _vm.disabled,
        'supportButtons': false,
      }
    }},on:{"change":_vm.permissionsChange}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }