











































































import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'

import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    HighlightAnchor
  }
})
export default class ChatActions extends Vue {

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  isChatActionActive(key:string):boolean {
    return this.$store.state.chatState.chat[key] === false && this.$store.state.chatState.backupConfig[key] === false
  }

  handleChatVisibilityButtonClick(): void {
    if (this.$store.state.chatState.chat.hidden) {
      this.$store.dispatch('runChatAction', 'showchat')
    } else {
      this.$store.dispatch('runChatAction', 'hidechat')
    }
  }

  handleSilentForAllButtonClick(): void {
    if (this.$store.state.chatState.chat.silent_for_all) {
      this.$store.dispatch('runChatAction', 'disablechatsilentforall')
    } else {
      this.$store.dispatch('runChatAction', 'enablechatsilentforall')
    }
  }

  handleDisableChatButtonClick(): void {
    if (this.$store.state.chatState.chat.enabled) {
      this.$store.dispatch('runChatAction', 'disablechat')
    } else {
      this.$store.dispatch('runChatAction', 'enablechat')
    }
  }

  handleSilentForUsersButtonClick(): void {
    if (this.$store.state.chatState.chat.silent_for_users) {
      this.$store.dispatch('runChatAction', 'disablechatsilentforusers')
    } else {
      this.$store.dispatch('runChatAction', 'enablechatsilentforusers')
    }
  }
}
